import OilinessTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-oiliness/oiliness/OilinessTemplate';

import { AMOUNT, GENDER, GREASY_SKIN_LOCATION } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinOiliness/Oiliness/OilinessTemplate'
};

const createStory = componentProps => () => ({
  components: { OilinessTemplate },
  componentProps,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  template: `
    <div :style='$options.wrapStyles'>
      <oiliness-template v-bind='$options.componentProps' />
    </div>`
});

export const GreasySkin = createStory({
  gender: GENDER.FEMALE,
  greasySkin: AMOUNT.CONSIDERABLY,
  greasySkinAiSelected: AMOUNT.MODERATELY,
  openFacialPores: AMOUNT.MODERATELY,
  openFacialPoresAiSelected: AMOUNT.MODERATELY,
  greasySkinLocations: GREASY_SKIN_LOCATION.TZONE_ONLY,
  greasySkinLocationsAiSelected: GREASY_SKIN_LOCATION.TZONE_ONLY,
  hasSomeGreasiness: true
});

export const NoGresySkin = createStory({
  gender: GENDER.FEMALE,
  greasySkin: AMOUNT.NO,
  greasySkinAiSelected: AMOUNT.NO
});
